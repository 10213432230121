import {BaseViewModel} from "../../data/base/BaseViewModel";
import { ProfileIterface } from "../Profile/modal/ProfileModal";
// import {BookRankingResponseModel} from "./models/BookRankingResponseModel";


export class LoginViewModel extends BaseViewModel {
  
    static _instance: LoginViewModel = new LoginViewModel();

    public static get instance() {
        if (LoginViewModel._instance === undefined) {
            LoginViewModel._instance = new LoginViewModel();
        }

        return LoginViewModel._instance;
    }
     async getProfile(): Promise<ProfileIterface> {
        return await this.getApiService().getUserProfile(this.handleError)
     }

    async getLogin( email:string,password:string): Promise<any> {
        return await this.getApiService().getLoginApiCall(email,password);
    }

    
}
