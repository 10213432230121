import { atom } from 'recoil';
import { BookRankingResponseModel } from "../pages/book-rankings/models/BookRankingResponseModel";
import {SingleBookRankingResponseModel} from "../pages/book-rankings/models/SingleBookRankingResponseModel";
import {YearlyProductResponseModel} from "../pages/book-rankings/models/YearlyProductResponseModel";
import {WeeklyProductResponseModel} from "../pages/book-rankings/models/WeeklyProductResponseModel";

export const bookRankingDataState = atom<BookRankingResponseModel | null>({
    key: 'bookRankingDataState',
    default: null,
});

export const bookRankingLoadingState = atom<boolean>({
    key: 'bookRankingLoadingState',
    default: true, // Default value is false
});

export const productViewDataState = atom<SingleBookRankingResponseModel | null>({
    key: 'productViewDataState',
    default: null,
});
export const getProductsByYearDataState = atom<YearlyProductResponseModel | null>({
    key: 'getProductsByYearDataState',
    default: null,
});
export const getProductsByWeekDataState = atom<WeeklyProductResponseModel | null>({
    key: 'getProductsByWeekDataState',
    default: null,
});
export const productViewLoadingState = atom<boolean>({
    key: 'productViewLoadingState',
    default: false, // Default value is false
});
// export const productViewSelector = selector({
//     key: 'productViewSelector',
//     get: ({ get }) => {
//         const data = get(productViewDataState);
//         const loading = get(productViewLoadingState);
//         return { data, loading };
//     },
// });
