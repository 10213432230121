import React from 'react';
import { BiChevronDown } from 'react-icons/bi';

export const BasicDropDown = ({ data, label, onChange, value }:any) => {
    const handleChange = (event:any) => {
        onChange(event.target.value);
    };

    return (
        <div className="dropdown">
            {/*<label>{label}</label>*/}
            <div className="relative inline-block">
                <select
                    className="flex rounded-md border border-b border-[#E6E6E6] py-3 px-4 font-medium text-black bg-white w-50 cursor-pointer appearance-none pr-10"
                    value={value}
                    onChange={handleChange}
                >
                    <option value="">{label}</option>
                    {data.map((menu:any, index:number) => (
                        <option key={index} value={menu.moduleName || menu.submoduleName}>
                            {menu.moduleName || menu.submoduleName}
                        </option>
                    ))}

                </select>
                <div
                    className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                    <BiChevronDown className="text-2xl text-[#000]"/>

                </div>

            </div>
        </div>
    );
};

export const SmallDropDown = ({ data, label, onChange, value }:any) => {
    const handleChange = (event:any) => {
        onChange(event.target.value);
    };

    return (
        <div className="dropdown">
            {/*<label>{label}</label>*/}
            <div className="relative inline-block">
                <select
                    className="flex rounded-md border border-b border-[#E6E6E6] py-3 px-4 font-medium text-black bg-white w-40 cursor-pointer appearance-none pr-10"
                    value={value}
                    onChange={handleChange}
                >
                    <option value="">{label}</option>
                    {data.map((menu:any, index:number) => (
                        <option key={index} value={menu.moduleName || menu.submoduleName}>
                            {menu.moduleName || menu.submoduleName}
                        </option>
                    ))}

                </select>
                <div
                    className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                    <BiChevronDown className="text-2xl text-[#000]"/>

                </div>

            </div>
        </div>
    );
};


