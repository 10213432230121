import React, { useState } from 'react';
import { FaArrowLeftLong } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import TabSwitcher from '../../../components/tabs-switcher/TabSwticher';
import InventoryOverView from './overview/InventoryOverView';

const DetailView = () => {
  const navigate = useNavigate();
  // const {id} = useParams()
  // const [tabValue, setTabValue] = useState(1);
  const [activeTab, setActiveTab] = useState(1);

  const tabs = [
    { id: 1, label: 'Overview' },
    { id: 2, label: 'COGS' },
    { id: 3, label: 'Stock-in' },
    { id: 4, label: 'Stock-out' },
    { id: 5, label: 'Vendors' },
    { id: 6, label: 'Royalty' },
  ];

  const handleTabChange = (tabId: number) => {
    setActiveTab(tabId);
  };

  // const handleChangeTabValue = (newValue: number) => {
  //     setTabValue(newValue);
  //     const selectedTab = tabs[newValue];
  //     navigate(selectedTab.id);
  // }

  // useEffect(() => {

  // }, [tabValue]);

  return (
    <>
      <div className="card-header border-0">
        <div className="flex justify-start flex-col gap-0">
          <div className="flex flex-row gap-2 items-center text-black mb-2 ">
            <FaArrowLeftLong
              className="cursor-pointer font-bold "
              onClick={() => navigate(-1)}
            />
            <p className="text-black font-poppins text-24 font-medium font-semibold">
              Back to Inventory
            </p>
          </div>
          <div>
            <TabSwitcher
              tabs={tabs}
              activeTab={activeTab}
              onTabChange={handleTabChange}
            />
            {activeTab === 1 && <InventoryOverView />}
            {activeTab === 2 && 'COGS'}
            {activeTab === 3 && 'Stocks-in'}
            {activeTab === 4 && 'Stocks-out'}
            {activeTab === 5 && 'vendors'}
            {activeTab === 6 && 'Royalty'}
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailView;
