import {AppApiService} from "../network/AppApiService";
import {PrefServices} from "../prefs/PrefServices";
import Swal from 'sweetalert2';
// import {PermissionsResponseModel} from "../network/models/PermissionsResponseModel";

export class BaseViewModel {
    static _instance: BaseViewModel = new BaseViewModel();
    getApiService = (): AppApiService => new AppApiService();
    getPrefsService = (): PrefServices => new PrefServices();

    set permissions(value: string[] | undefined) {
        this._permissions = value;
    }

    get permissions(): string[] | undefined {
        return this._permissions;
    }

    private _permissions: string[] | undefined;


    public static get instance() {
        if (BaseViewModel._instance === undefined) {
            BaseViewModel._instance = new BaseViewModel();
        }

        return BaseViewModel._instance;
    }


    //region Handle Error
    handleError = (error: any) => {
        console.log(error.response);
        if (error.response && error.response.data && error.response.data.message) {
            this.showError(error.response.data.message);
        } else if (error.message) {
            this.showError(error.message)
        } else if (error) {
            this.showError(error)
        } else {
            this.showError("Some Error Occurred");
        }
    };
    //endregion


    //region Dialogs
    showError(message: string) {
        Swal.fire({
            title: "Error",
            text: message,
            icon: 'error',
            customClass: {
                confirmButton: 'bg-primary btn-focus',
                closeButton: 'btn bg-light btn-focus',
                denyButton: 'btn bg-light btn-focus ',
                cancelButton: 'btn bg-light  btn-focus ',
            },


        });
    }

    showSuccess(message: string) {
        Swal.fire({
            title: "Success",
            text: message,
            icon: 'success'
        });
    }

    showSuccessToast(message: string) {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            customClass: {
                confirmButton: 'bg-primary btn-focus',
                closeButton: 'btn bg-light btn-focus',
                denyButton: 'btn bg-light btn-focus ',
                cancelButton: 'btn bg-light  btn-focus ',
            },
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            }
        });

        Toast.fire({
            icon: 'success',
            title: message
        })
    }

    showErrorToast(message: string) {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            customClass: {
                confirmButton: 'bg-primary btn-focus',
                closeButton: 'btn bg-light btn-focus',
                denyButton: 'btn bg-light btn-focus ',
                cancelButton: 'btn bg-light  btn-focus ',
            },
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            }
        });

        Toast.fire({
            icon: 'error',
            title: message
        })
    }

    showWarning(message: string, title: string = "Warning", buttonText: string = "Yes", callback: Function) {
        Swal.fire({
            title: title,
            html: message,
            icon: 'warning',
            showConfirmButton: true,
            showDenyButton: true,
            confirmButtonText: buttonText,
            customClass: {
                confirmButton: 'bg-primary btn-focus',
                closeButton: 'btn bg-light btn-focus',
                denyButton: 'btn bg-light btn-focus ',
                cancelButton: 'btn bg-light  btn-focus ',
            }
        }).then(result => {
            callback(result)
        });
    }

    showInfo(message: string) {
        Swal.fire({
            title: "Information",
            text: message,
            icon: 'info'
        });
    }

    //endregion


    // async ability(): Promise<PermissionsResponseModel> {
    //     return await this.getApiService().getPermissionsApiCall(this.handleError)
    //         .then((response: PermissionsResponseModel) => {
    //             if (response && response.data) {
    //                 this.permissions = response.data;
    //             }
    //             return response;
    //         })
    // }

    can(permission: string): boolean {
        if (this.permissions && this.permissions.length > 0) {
            return this.permissions.includes(permission);
        }
        return false;
    }


}
