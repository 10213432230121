import { Suspense, lazy, useEffect, useState } from 'react';
import SignIn from './pages/Authentication/SignIn';
import SignUp from './pages/Authentication/SignUp';
import Loader from './components/loader/Loader';
import routes from './routes';
import React from 'react';
import PrivateWrapper from './routes/ProtectedRoute';
import { RecoilRoot } from 'recoil';
import { ToastContainer } from 'react-toastify';
import PageNotFound from './components/page-not-found/PageNotFound';
import { HashRouter, Route, Routes } from 'react-router-dom';
const DefaultLayout = lazy(() => import('./layout/DefaultLayout'));

function App() {
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <>
      <RecoilRoot>
        <ToastContainer />
        <HashRouter>
          <Routes>
            <Route path="/" element={<SignIn />} />
            <Route path="/" element={<SignUp />} />
            <Route element={<DefaultLayout />}>
              <Route path={'/*'} element={<PageNotFound />} />
              {/* <Route
                            index
                            element={
                                <PrivateWrapper>
                                    <Dashboard />
                                </PrivateWrapper>
                            }
                        /> */}
              {routes.map(({ path, component: Component }) => (
                <Route
                  key={path}
                  path={path}
                  element={
                    <PrivateWrapper>
                      <Suspense fallback={<Loader />}>
                        <Component />
                      </Suspense>
                    </PrivateWrapper>
                  }
                />
              ))}
            </Route>
          </Routes>
        </HashRouter>
      </RecoilRoot>
    </>
  );
}

export default App;
