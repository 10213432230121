import { ApexOptions } from 'apexcharts';
import React from 'react';
import ReactApexChart from 'react-apexcharts';

interface BarChartProps {
    options: ApexOptions;
    series: any;
    handleSelectChange: any
}

const BarChart: React.FC<BarChartProps> = ({ options, series,handleSelectChange }) => {

    return (
        <div className="col-span-12 rounded-sm border border-stroke bg-white p-7.5 shadow-default dark:border-strokedark dark:bg-boxdark xl:col-span-4">
            <div className="mb-4 justify-between gap-4 sm:flex">
                <div>
                    <h4 className="text-xl font-semibold text-black dark:text-white">
                        Profit this week
                    </h4>
                </div>
                <div>
                    <div className="relative z-20 inline-block">
                        <select
                            name="#"
                            id="#"
                            className="relative z-20 inline-flex  bg-black text-white rounded py-1 pl-3 pr-8 text-sm font-medium outline-none cursor-pointer"
                            onChange={handleSelectChange} // Attach an event handler
                        >
                            <option value="7">This Week</option>
                            <option value="14">last 2 Week</option>
                            <option value="21">last 3 Week</option>
                        </select>
                        <span className="absolute top-1/2 right-3 z-10 -translate-y-1/2">
              {/* Your dropdown arrow SVG */}
            </span>
                    </div>
                </div>
            </div>

            <div>
                <div id="chartTwo" className="-ml-5 -mb-9">
                    <ReactApexChart
                        options={options}
                        series={series}
                        type="bar"
                        height={350}
                    />
                </div>
            </div>
        </div>
    );
};

export default BarChart;
