import {Endpoints} from "./ApiEndPoints";
import {ApiClient} from "./ApiClient";
import axios from "axios";

export type PaginationParams = {
    page?: number,
    perPage?: number,
    totalPages?: string
}
export type filtersChartParams = {
    year?: number,
}
export type idParams = {
    id?: number | string
}

export class AppApiService {

    apiClient = new ApiClient;

    //region Handle Response
    static handleApiResponse(response: any, handleError?: Function) {
        if (response.data && response.data.success) {
        } else {
            if (handleError) {
                handleError(response.data)
            }
        }
        return response.data;
    }

    //endregion

    //region Permissions
    /**
     * Get Project Types

     * @param handleError
     */
    // getPermissionsApiCall = async function (handleError: Function): Promise<axios.AxiosResponse<any> | void> {
    //     return await axios.get(Endpoints.permissions)
    //         .then((response: any) => AppApiService.handleApiResponse(response, handleError))
    //         .catch((err: any) => {
    //             handleError(err)
    //         });
    // };

    //login
    getLoginApiCall = async (email: string, password: string): Promise<any> => {
        try {
            const response = await axios.post(Endpoints.login(), {email, password});
            return response.data;
        } catch (error) {
            throw error;
        }
    };
    //user profile
    getUserProfile = async (handleError: any): Promise<any> => {
        const response = await this.apiClient.makeApiCall("get", Endpoints.userProfile, handleError, {},)
        return response;
    };
    // base product for books
    getBaseProductForBooks = async (handleError: any, params: PaginationParams): Promise<any> => {
        const queryParams = new URLSearchParams()
        for (const [key, value] of Object.entries(params)) {
            queryParams.append(key, value.toString())
        }
        const response = await this.apiClient.makeApiCall("get", `${Endpoints.baseProductWithModule}?${queryParams.toString()}`, handleError, {},)
        return response;
    };

    getBaseSingleProductWithModule = async (handleError: any, params: idParams): Promise<any> => {
        const queryParams = new URLSearchParams()
        for (const [key, value] of Object.entries(params)) {
            queryParams.append(key, value.toString())
        }
        const response = await this.apiClient.makeApiCall("get", `${Endpoints.baseSingleProductWithModule}?${queryParams.toString()}`, handleError, {},)
        return response;
    };

    getSingleBaseProductPnlYearly = async (handleError: any, params: idParams): Promise<any> => {
        const queryParams = new URLSearchParams()
        for (const [key, value] of Object.entries(params)) {
            queryParams.append(key, value.toString())
        }
        const response = await this.apiClient.makeApiCall("get", `${Endpoints.singleBaseProductPnlYearly}?${queryParams.toString()}`, handleError, {},)
        return response;
    };
    getSingleBaseProductPnlMonthly = async (handleError: any, params: idParams): Promise<any> => {
        const queryParams = new URLSearchParams()
        for (const [key, value] of Object.entries(params)) {
            queryParams.append(key, value.toString())
        }
        const response = await this.apiClient.makeApiCall("get", `${Endpoints.singleBaseProductPnlMonthly}?${queryParams.toString()}`, handleError, {},)
        return response;
    };
    getSingleBaseProductPnlWeekly = async (handleError: any, params: idParams): Promise<any> => {
        const queryParams = new URLSearchParams()
        for (const [key, value] of Object.entries(params)) {
            queryParams.append(key, value.toString())
        }
        const response = await this.apiClient.makeApiCall("get", `${Endpoints.singleBaseProductPnlWeekly}?${queryParams.toString()}`, handleError, {},)
        return response;
    };

    getPNLData = async (handleError: any, params: PaginationParams): Promise<any> => {
        const queryParams = new URLSearchParams()
        for (const [key, value] of Object.entries(params)) {
            queryParams.append(key, value.toString())
        }
        const response = await this.apiClient.makeApiCall("get", `${Endpoints.fetchPNL}?${queryParams.toString()}`, handleError, {},)
        return response;
    };
// book ranking api
    getBookRankingApiCall = async (handleError: any, params: PaginationParams): Promise<any> => {
        const queryParams = new URLSearchParams()
        for (const [key, value] of Object.entries(params)) {
            queryParams.append(key, value.toString())
        }
        const response = await this.apiClient.makeApiCall("get", `${Endpoints.bookRanking}?${queryParams.toString()}`, handleError, {},)
        return response;
    };
    getProductViewData = async (handleError: any, params: PaginationParams) => {
        const queryParams = new URLSearchParams()
        for (const [key, value] of Object.entries(params)) {
            queryParams.append(key, value.toString())
        }
        const response = await this.apiClient.makeApiCall("get", `${Endpoints.productsViewData}?${queryParams}`, handleError, {},)
        return response;
    };
    getProductsDetailApiCallFilterByYearly = async (handleError: any, params: filtersChartParams) => {
        const queryParams = new URLSearchParams();
        for (const [key, value] of Object.entries(params)) {
            queryParams.append(key, value.toString())
        }
        console.log(params, "from app api queryParams");
        const response = await this.apiClient.makeApiCall("get", `${Endpoints.productsFilterByYearly}?${queryParams}`, handleError, {});
        return response;
    };
    getProductsDetailApiCallFilterByWeekly = async (handleError: any, params: filtersChartParams) => {
        const queryParams = new URLSearchParams();
        for (const [key, value] of Object.entries(params)) {
            queryParams.append(key, value.toString())
        }
        console.log(params, "from app api queryParams");
        const response = await this.apiClient.makeApiCall("get", `${Endpoints.productsFilterByWeekly}?${queryParams}`, handleError, {});
        return response;
    };


}
