import React, {ChangeEvent, useEffect, useState} from 'react'
import BooksTableDetails from "./books-table/BooksTable";
import FiltersComponent from "../../components/filters/FiltersComponent";
import {BooksViewModel} from "./BooksViewModel";
import {Datum, BooksResponseModel} from "./models/BookResponseModel";
import Loader from "../../components/loader/Loader";

const model = BooksViewModel.instance;

const AllBooks = () => {
    const [allBooksData, setAllBooksData] = useState<BooksResponseModel>();
    const [page, setPage] = useState<number>(1);
    const [isLoading, setIsLoading] = useState(false)
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const limit = 10;

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                setIsLoading(true)
                const params = {
                    page: page,
                    perPage: limit,
                    from:fromDate,
                    to:toDate
                };
                const response = await model.getBooksData(params);
                if (response && response.success) {
                    setAllBooksData(response)
                    // setIsLoading(false)
                }
            } catch (error) {
                console.error("Login error:", error);
            }
            finally {
                setIsLoading(false)
            }
        };
        fetchProfile();
    }, [page,fromDate, toDate]);

    const handleFromDateChange = (e: ChangeEvent<HTMLInputElement>) => {
        const selectedDate = e.target.value;
        setFromDate(selectedDate);
        console.log(selectedDate,"selectedDate")
    };
    const handleToDateChange = (e: ChangeEvent<HTMLInputElement>) => {
        const selectedDate = e.target.value;
        console.log(selectedDate,"selectedDate")
        setToDate(selectedDate);
    };

    const clearFilter = () => {
        const currentUrl = window.location.href;
        const url = new URL(currentUrl);
        url.searchParams.delete("from");
        url.searchParams.delete("to");
        const newUrl = url.toString();
        window.history.pushState({}, "", newUrl);
        setFromDate("");
        setToDate("");
    };

    return (
        <>
            {isLoading ? (
                    <Loader/>
                ) :

                <div className="card-header border-0">
                    <div className="card-tools flex flex-col gap-7">
                        <div>
                            <FiltersComponent
                                fromDate={fromDate}
                                from={fromDate}
                                to={toDate}
                                handleFromDateChange={handleFromDateChange}
                                handleToDateChange={handleToDateChange}
                                clearFilter={clearFilter}
                            />
                        </div>
                        <div>
                            <BooksTableDetails
                                data={allBooksData?.data?.data as Datum[]}
                                currentPage={page}
                                totalPages={allBooksData?.data?.last_page || 0}
                                setPage={setPage}
                                fromDate={fromDate}
                                toDate={toDate}
                            />
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
export default AllBooks
