import React, {ChangeEvent, useState} from "react";
import Loader from "../../components/loader/Loader";
import FiltersComponent from "../../components/filters/FiltersComponent";
import {BasicDropDown, SmallDropDown} from "../../components/drop-down/BasicDropDown";
import InventoryTableDetails from "./inventory-table/InventoryTable";

const InventoryPages = () => {
    const [isLoading] = useState(false)
    const [fromDate] = useState('');
    const [toDate] = useState('');
    const [selectedPlace, setSelectedPlace] = useState('');
    const [selectedFile, setSelectedFile] = useState('');


    const handleFromDateChange = (e: ChangeEvent<HTMLInputElement>) => {

    };
    const handleToDateChange = (e: ChangeEvent<HTMLInputElement>) => {

    };
    const clearFilter = () => {

    };
    const dropdowndataForPlace = [
        {moduleName: 'Delhi'},
        {moduleName: 'Gujarat'},
        {submoduleName: 'Uttar Pradesh'},
        {submoduleName: 'MP'},
        {submoduleName: 'Karnataka'},
        {submoduleName: 'Tamil Nadu'},

    ];
    const dropdowndataForFile = [
        {moduleName: 'Export in pdf'},
        {moduleName: 'Export in excel'},
        {submoduleName: 'export in docx'},
        {submoduleName: 'others'},
    ];


    const handleDropdownForPlace = (value:any) => {
        setSelectedPlace(value);
    };
    const handleDropdownForExport = (value:any) => {
        setSelectedFile(value)
    }
    return (
        <>
            {isLoading ? (
                    <Loader/>
                ) :

                <div className="card-header border-0">
                    <div className="card-tools flex flex-col gap-7">
                        {/*filters component*/}
                        <div className="flex flex-row  justify-between">
                            <FiltersComponent
                                fromDate={fromDate}
                                from={fromDate}
                                to={toDate}
                                handleFromDateChange={handleFromDateChange}
                                handleToDateChange={handleToDateChange}
                                clearFilter={clearFilter}
                                forBooks={true}
                            />

                            <div className="flex gap-4">
                                <BasicDropDown
                                    data={dropdowndataForPlace}
                                    label="Select City"
                                    onChange={handleDropdownForPlace}
                                    value={selectedPlace}
                                />

                                <SmallDropDown
                                    data={dropdowndataForFile}
                                    label="Export file"
                                    onChange={handleDropdownForExport}
                                    value={selectedFile}
                                />

                            </div>
                        </div>
                        {/*table component*/}
                        <div>
                           <InventoryTableDetails/>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default InventoryPages