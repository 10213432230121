export const dummyData = [
    {
        id: 1,
        "secondCol": "second col",
        "thirdCol": "Book number 1",
        "warehouseData": {
            "warehouseName": "Warehouse Name",
            "currentStock": "Current Stock",
            "items": [
                {
                    "itemName": "Main Office",
                    "itemStock": 113
                },
                {
                    "itemName": "Main Office",
                    "itemStock": 113
                },
                {
                    "itemName": "Main Office",
                    "itemStock": 113
                }
            ]
        },
        "fourthCol": 200,
        "fifthCol": 700,
        "sixthCol": "Avg level"
    },
    {
        id: 2,
        "secondCol": "second col",
        "thirdCol": "Book number 2",
        "warehouseData": {
            "warehouseName": "Warehouse Name",
            "currentStock": "Current Stock",
            "items": [
                {
                    "itemName": "Main Office",
                    "itemStock": 113
                },
                {
                    "itemName": "Main Office",
                    "itemStock": 113
                },
                {
                    "itemName": "Main Office",
                    "itemStock": 113
                }
            ]
        },
        "fourthCol": 200,
        "fifthCol": 900,
        "sixthCol": "Avg level"
    },
    {
        id: 3,
        "secondCol": "second col",
        "thirdCol": "Book number 3",
        "warehouseData": {
            "warehouseName": "Warehouse Name",
            "currentStock": "Current Stock",
            "items": [
                {
                    "itemName": "Main Office",
                    "itemStock": 113
                },
                {
                    "itemName": "Main Office",
                    "itemStock": 113
                },
                {
                    "itemName": "Main Office",
                    "itemStock": 113
                }
            ]
        },
        "fourthCol": 200,
        "fifthCol": 1200,
        "sixthCol": "Avg level"
    },
    {
        id: 4,
        "secondCol": "second col",
        "thirdCol": "Book number 4",
        "warehouseData": {
            "warehouseName": "Warehouse Name",
            "currentStock": "Current Stock",
            "items": [
                {
                    "itemName": "Main Office",
                    "itemStock": 113
                },
                {
                    "itemName": "Main Office",
                    "itemStock": 113
                },
                {
                    "itemName": "Main Office",
                    "itemStock": 113
                }
            ]
        },
        "fourthCol": 200,
        "fifthCol": 5400,
        "sixthCol": "Avg level"
    },
    {
        id: 5,
        "secondCol": "second col",
        "thirdCol": "Book number 5",
        "warehouseData": {
            "warehouseName": "Warehouse Name",
            "currentStock": "Current Stock",
            "items": [
                {
                    "itemName": "Main Office",
                    "itemStock": 113
                },
                {
                    "itemName": "Main Office",
                    "itemStock": 113
                },
                {
                    "itemName": "Main Office",
                    "itemStock": 113
                }
            ]
        },
        "fourthCol": 200,
        "fifthCol": 400,
        "sixthCol": "Avg level"
    },
    {
        id: 6,
        "secondCol": "second col",
        "thirdCol": "Book number 6",
        "warehouseData": {
            "warehouseName": "Warehouse Name",
            "currentStock": "Current Stock",
            "items": [
                {
                    "itemName": "Main Office",
                    "itemStock": 113
                },
                {
                    "itemName": "Main Office",
                    "itemStock": 113
                },
                {
                    "itemName": "Main Office",
                    "itemStock": 113
                }
            ]
        },
        "fourthCol": 200,
        "fifthCol": 1100,
        "sixthCol": "Avg level"
    },
    {
        id: 7,
        "secondCol": "second col",
        "thirdCol": "Book number 7",
        "warehouseData": {
            "warehouseName": "Warehouse Name",
            "currentStock": "Current Stock",
            "items": [
                {
                    "itemName": "Main Office",
                    "itemStock": 113
                },
                {
                    "itemName": "Main Office",
                    "itemStock": 113
                },
                {
                    "itemName": "Main Office",
                    "itemStock": 113
                }
            ]
        },
        "fourthCol": 200,
        "fifthCol": 900,
        "sixthCol": "Avg level"
    },
    {
        id: 8,
        "secondCol": "second col",
        "thirdCol": "Book number 8",
        "warehouseData": {
            "warehouseName": "Warehouse Name",
            "currentStock": "Current Stock",
            "items": [
                {
                    "itemName": "Main Office",
                    "itemStock": 113
                },
                {
                    "itemName": "Main Office",
                    "itemStock": 113
                },
                {
                    "itemName": "Main Office",
                    "itemStock": 113
                }
            ]
        },
        "fourthCol": 200,
        "fifthCol": 9900,
        "sixthCol": "Avg level"
    },
    {
        id: 9,
        "secondCol": "second col",
        "thirdCol": "Book number 9",
        "warehouseData": {
            "warehouseName": "Warehouse Name",
            "currentStock": "Current Stock",
            "items": [
                {
                    "itemName": "Main Office",
                    "itemStock": 113
                },
                {
                    "itemName": "Main Office",
                    "itemStock": 113
                },
                {
                    "itemName": "Main Office",
                    "itemStock": 113
                }
            ]
        },
        "fourthCol": 200,
        "fifthCol": 900,
        "sixthCol": "Avg level"
    }, {
        id: 9,
        "secondCol": "second col",
        "thirdCol": "Book number 9",
        "warehouseData": {
            "warehouseName": "Warehouse Name",
            "currentStock": "Current Stock",
            "items": [
                {
                    "itemName": "Main Office",
                    "itemStock": 113
                },
                {
                    "itemName": "Main Office",
                    "itemStock": 113
                },
                {
                    "itemName": "Main Office",
                    "itemStock": 113
                }
            ]
        },
        "fourthCol": 200,
        "fifthCol": 900,
        "sixthCol": "Avg level"
    },
    {
        id: 9,
        "secondCol": "second col",
        "thirdCol": "Book number 9",
        "warehouseData": {
            "warehouseName": "Warehouse Name",
            "currentStock": "Current Stock",
            "items": [
                {
                    "itemName": "Main Office",
                    "itemStock": 113
                },
                {
                    "itemName": "Main Office",
                    "itemStock": 113
                },
                {
                    "itemName": "Main Office",
                    "itemStock": 113
                }
            ]
        },
        "fourthCol": 200,
        "fifthCol": 900,
        "sixthCol": "Avg level"
    },
    {
        id: 9,
        "secondCol": "second col",
        "thirdCol": "Book number 9",
        "warehouseData": {
            "warehouseName": "Warehouse Name",
            "currentStock": "Current Stock",
            "items": [
                {
                    "itemName": "Main Office",
                    "itemStock": 113
                },
                {
                    "itemName": "Main Office",
                    "itemStock": 113
                },
                {
                    "itemName": "Main Office",
                    "itemStock": 113
                }
            ]
        },
        "fourthCol": 200,
        "fifthCol": 900,
        "sixthCol": "Avg level"
    },
    {
        id: 9,
        "secondCol": "second col",
        "thirdCol": "Book number 9",
        "warehouseData": {
            "warehouseName": "Warehouse Name",
            "currentStock": "Current Stock",
            "items": [
                {
                    "itemName": "Main Office",
                    "itemStock": 113
                },
                {
                    "itemName": "Main Office",
                    "itemStock": 113
                },
                {
                    "itemName": "Main Office",
                    "itemStock": 113
                }
            ]
        },
        "fourthCol": 200,
        "fifthCol": 900,
        "sixthCol": "Avg level"
    }, {
        id: 9,
        "secondCol": "second col",
        "thirdCol": "Book number 9",
        "warehouseData": {
            "warehouseName": "Warehouse Name",
            "currentStock": "Current Stock",
            "items": [
                {
                    "itemName": "Main Office",
                    "itemStock": 113
                },
                {
                    "itemName": "Main Office",
                    "itemStock": 113
                },
                {
                    "itemName": "Main Office",
                    "itemStock": 113
                }
            ]
        },
        "fourthCol": 200,
        "fifthCol": 900,
        "sixthCol": "Avg level"
    }, {
        id: 9,
        "secondCol": "second col",
        "thirdCol": "Book number 9",
        "warehouseData": {
            "warehouseName": "Warehouse Name",
            "currentStock": "Current Stock",
            "items": [
                {
                    "itemName": "Main Office",
                    "itemStock": 113
                },
                {
                    "itemName": "Main Office",
                    "itemStock": 113
                },
                {
                    "itemName": "Main Office",
                    "itemStock": 113
                }
            ]
        },
        "fourthCol": 200,
        "fifthCol": 900,
        "sixthCol": "Avg level"
    }

]
