import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeftLong } from 'react-icons/fa6';
import TabSwitcher from '../../../components/tabs-switcher/TabSwticher';
import BrandOverview from './brand-overview/BrandOverview';

const MarketingBrandOverview = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(1);

  const tabs = [
    { id: 1, label: 'Overview' },
    { id: 2, label: 'Campaigns' },
    { id: 3, label: 'Sales' },
  ];
  const handleTabChange = (tabId: number) => {
    setActiveTab(tabId);
  };

  return (
    <>
      <div className="card-header border-0">
        <div className="flex justify-start flex-col gap-0">
          <div className="flex flex-row gap-2 items-center text-black mb-2 ">
            <FaArrowLeftLong
              className="cursor-pointer font-bold "
              onClick={() => navigate(-1)}
            />
            <p className="text-black font-poppins text-24 font-medium font-semibold">
              Back to Marketing Dashboard
            </p>
          </div>
          <div>
            <TabSwitcher
              tabs={tabs}
              activeTab={activeTab}
              onTabChange={handleTabChange}
            />
            {activeTab === 1 && <BrandOverview />}
            {activeTab === 2 && 'Campaigns'}
            {activeTab === 3 && 'Sales'}
          </div>
        </div>
      </div>
    </>
  );
};

export default MarketingBrandOverview;
