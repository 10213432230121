import React, {useEffect, useState} from "react";
import ProductCards from "../../../components/book-ranking-cards/ProductsCards";
import {useParams} from "react-router-dom";
import {BookRankingViewModel} from "../BookRankingViewModel";
import LinesChart from "../../../components/lines-chart/LinesCharts";
import {useRecoilState} from "recoil";
import {
    getProductsByWeekDataState,
    getProductsByYearDataState,
    productViewDataState
} from "../../../atoms/BookRankingAtoms";
import BarChart from "../../../components/bar-chart/BarChart";

const model = BookRankingViewModel.instance;

const ProductsView = () => {
    const [productChennelData, setProductChennelData] = useRecoilState(productViewDataState);
    const [productDatabyYearly, setProductDataByYearly] = useRecoilState(getProductsByYearDataState)
    const [filterByWeek, setFilterByWeek] = useRecoilState(getProductsByWeekDataState)
    const [selectedId, setSelectedId] = useState<any>();
    const [selectedYear] = useState("2023");
    const [selectWeek] = useState("7")
    const [selectedCardId, setSelectedCardId] = useState<number | null>(null);

    const {id} = useParams();
    const onSelectProductHandler = async (id: number) => {
        try {
            const params = {
                id: id,
            };
            const response = await model.getProductViewData(params);
            setProductChennelData(response);
            setSelectedId(response.data.name);
            if (response && response.success) {
                const getSpID = response.data.sp_id
                onProductViewByYearly(selectedYear, getSpID)
                handleWeekSelector(selectedYear, selectWeek, getSpID)
            }

        } catch (error) {
            console.error("Error:", error);
        }
    };
    const onProductViewByYearly = async (selectedYear: any, getSpID: number) => {
        try {
            const params = {
                year: selectedYear,
                sp_id: getSpID,
            };
            console.log(params, "params")
            const response = await model.getProductsDetailApiCallFilterByYearly(params);
            setProductDataByYearly(response)
            // setSelectedYear(response)
        } catch (error) {
            console.error("Error:", error);
        }
    }
    useEffect(() => {
        onSelectProductHandler(Number(id));
    }, [id]);
    const handleWeekSelector = async (selectedYear: any, selectWeek: any, getSpID: number) => {
        try {
            const params = {
                year: selectedYear,
                number_of_days: selectWeek,
                sp_id: getSpID,
            };
            const response = await model.getProductsDetailApiCallFilterByWeekly(params);
            console.log(response, "handleWeekSelector");
            setFilterByWeek(response);
        } catch (error) {
            console.error("Error:", error);
        }
    }
    const onSelectWeeklyProduct = (e: any) => {
        const selectedWeek = e.target.value;
        const spID = productChennelData?.data?.sp_id || 0;
        handleWeekSelector(selectedYear, selectedWeek, spID)
    }
    const onSelectYearProduct = (e: any) => {
        const selectedYear = e.target.value;
        const spID = productChennelData?.data?.sp_id || 0;
        onProductViewByYearly(selectedYear, spID);
    }
    const handleChannelData = async (base_channel_id: number) => {
        try {
            const params = {
                year: selectedYear,
                base_channel_id: base_channel_id,
            };
            const response = await model.getProductsDetailApiCallFilterByYearly(params);
            setProductDataByYearly(response);
            setSelectedCardId(base_channel_id)
            // setSelectedYear(response)
        } catch (error) {
            console.error("Error:", error);
        }
    };
    interface CustomApexOptions {
        title: {
            text: string;
            align: 'center';
        };
        xaxis: {
            categories: any[];
            type: 'category';
            labels: {};
            tickAmount: 'dataPoints';
        };
    }
    const currentMonth = new Date().getMonth();
    const months = [];

    for (let i = 0; i <= currentMonth; i++) {
        const monthName = new Date(0, i).toLocaleString('en', { month: 'long' });
        months.push(monthName);
    }
    // productDatabyYearly?.data?.map((item) =>
    //     moment().month(item.month_number - 1).format('MMMM')
    // ) || []
    const chartDataForLine: {
        options: CustomApexOptions;
        series: any[];
    } = {
        options: {
            title: {
                text: 'Monthly Sales and Quantity',
                align: 'center',
            },
            xaxis: {
                categories: months,
                type: 'category',
                labels: {},
                tickAmount: 'dataPoints',
            },
        },

        series: [
            {
                name: 'Total Revenue',
                data: productDatabyYearly?.data?.map((item) => item.total_amount) || [],
            },
            {
                name: 'Total Sales',
                data: productDatabyYearly?.data?.map((item) => item.total_quantity) || [],
            },
        ],
    };
    console.log(selectWeek, "selectWeek")
    console.log(chartDataForLine, "chartData")
    const getTotalAmount = () => {
        const valueforAmount = filterByWeek?.data;
        const totalAmountArray = valueforAmount?.map((item) =>
            item.data.length > 0 ? Number(item?.data[0]?.total_amount) : 0
        );
        return totalAmountArray && totalAmountArray;
    }

    const getTotalQuantity = () => {
        const valueForAmount = filterByWeek?.data;
        const totalQuantityArray = valueForAmount?.map((item) =>
            item.data.map((sec)=>sec.total_quantity)
        );
        return totalQuantityArray && totalQuantityArray;
    }
    const chartDataForBarGraph = {
        options: {
            xaxis: {
                categories: filterByWeek?.data.map((item: any) => item.day_name) || [], // Use the date as x-axis labels
            },
        },
        series: [
            {
                name: "Total Quantity",
                data: getTotalAmount()
            },
            {
                name: "Total Amount",
                data: getTotalQuantity(),
            },
        ],
    };


    console.log(chartDataForBarGraph, "chartDataForBarGraph")

    return (
        <>
            <div>
                <div className=" flex  justify-start">
                    <h1 className="text-xl text-black">Product: </h1>
                    <h1 className="text-xl font-bold">{selectedId}</h1>
                    <div className="ml-20">
                        <select
                            className="inline-flex justify-center rounded-md bg-black  font-medium text-white hover:bg-opacity-90 xl:px-20 py-2 text-sm h-10">
                            <option value="2023">All Products</option>

                        </select>
                        <select onChange={onSelectYearProduct}
                                className="inline-flex justify-center rounded-md bg-black  font-medium text-white hover:bg-opacity-90 xl:px-20 py-2 text-sm h-10 ml-10">
                            <option value="2023">2023</option>
                            <option value="2022">2022</option>
                            <option value="2021">2021</option>
                            <option value="2020">2020</option>
                            <option value="2019">2019</option>
                        </select>
                    </div>
                </div>

                <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 my-10">
                    {productChennelData?.data?.product_items.map((item: any) => (
                        <ProductCards
                            productItem={item}
                            key={item.id}
                            handleChannelData={() => handleChannelData(item.base_channel_id)}
                            selectedCardId={selectedCardId}
                        />
                    ))}

                </div>

                <div className="mt-4 grid grid-cols-12 gap-4 md:mt-6 md:gap-6 2xl:mt-7.5 2xl:gap-7.5">
                    <div className="col-span-12">
                        <LinesChart options={chartDataForLine.options} series={chartDataForLine.series}/>
                    </div>

                    <div className="col-span-12">
                        <BarChart options={chartDataForBarGraph.options} series={chartDataForBarGraph.series}
                                  handleSelectChange={onSelectWeeklyProduct}/>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProductsView;
