// this file is only for dummy data
export const cardDataForRadialChart = [
    {
        id: 1,
        series: [51],
        colors: ["#FE5512"],
        valueColor: "#FE5512",
        label: "Total Budget",
        totalBudget: "$2,300",
    },
    {
        id: 2,
        series: [68],
        colors: ["#DB1B1B"],
        valueColor: "#DB1B1B",
        label: "Total Expenses",
        totalBudget: "$2,300",
    },
    {
        id: 3,
        series: [32],
        colors: ["#0BD1F4"],
        valueColor: "#0BD1F4",
        label: "Remaining budget",
        totalBudget: "$2,300",
    },
    // Add data for other cards here
];
export const brandCardData = [
    {
        id: 1,
        title: "Branding",
        campaignsCreated: 777,
        liveCampaigns: 100,
        totalExpenses: "$2,300",
    },
    {
        id: 2,
        title: "Marketing",
        campaignsCreated: 777,
        liveCampaigns: 100,
        totalExpenses: "$2,300",
    },
    {
        id: 3,
        title: "Distribution",
        campaignsCreated: 777,
        liveCampaigns: 100,
        totalExpenses: "$2,300",

    }
]
export const campaignTableData = [
    {
        id: 1,
        activityName: 'Marketing Activity - 23303',
        activityDetailsLink: 'View activity details',
        activityStatus: true,
        bookName: 'Book Name',
        distribution: [{id: 1, distribution_name: 'Distribution'}, {id: 2, distribution_name: 'Branding'}, {
            id: 3,
            distribution_name: 'Marketing'
        }],
        channels: [
            {id:1,
                name: 'Amazon'},
            {id:2,name: 'google ads'},
            {id:3,name: 'meta ads'},
        ],
        totalExpenses: '$2,300',
        totalIncome: '$1,300',
        startDate: '23/09/2023',
        endDate: '23/09/2023',
        quantity: 397,
        reachData: [
            {label: 'Reach', value: 300},
            {label: 'Reach', value: 30000},
            {label: 'Reach', value: 300},
        ],
    },
    {
        id: 2,
        activityName: 'Marketing Activity - 23303',
        activityDetailsLink: 'View activity details',
        activityStatus: false,
        bookName: 'Book Name',
        distribution: [{id: 1, distribution_name: 'Distribution'}, {id: 2, distribution_name: 'Branding'}, {
            id: 3,
            distribution_name: 'Marketing'
        }],
        channels: [
            {id:1,
                name: 'Amazon'},
            {id:2,name: 'google ads'},
            {id:3,name: 'meta ads'},
        ],
        totalExpenses: '$2,300',
        totalIncome: '$1,300',
        startDate: '23/09/2023',
        endDate: '23/09/2023',
        quantity: 397,
        reachData: [
            {label: 'Reach', value: 300},
            {label: 'Reach', value: 30000},
            {label: 'Reach', value: 300},
        ],
    },
    {
        id: 3,
        activityName: 'Marketing Activity - 23303',
        activityDetailsLink: 'View activity details',
        activityStatus: false,
        bookName: 'Book Name',
        distribution: [{id: 1, distribution_name: 'Distribution'}, {id: 2, distribution_name: 'Branding'}, {
            id: 3,
            distribution_name: 'Marketing'
        }],
        channels: [
            {id:1,
                name: 'Amazon'},
            {id:2,name: 'google ads'},
            {id:3,name: 'meta ads'},
        ],
        totalExpenses: '$2,300',
        totalIncome: '$1,300',
        startDate: '23/09/2023',
        endDate: '23/09/2023',
        quantity: 397,
        reachData: [
            {label: 'Reach', value: 300},
            {label: 'Reach', value: 30000},
            {label: 'Reach', value: 300},
        ],
    },
    {
        id: 4,
        activityName: 'Marketing Activity - 23303',
        activityDetailsLink: 'View activity details',
        activityStatus: true,
        bookName: 'Book Name',
        distribution: [{id: 1, distribution_name: 'Distribution'}, {id: 2, distribution_name: 'Branding'}, {
            id: 3,
            distribution_name: 'Marketing'
        }],
        channels: [
            {id:1,
                name: 'Amazon'},
            {id:2,name: 'google ads'},
            {id:3,name: 'meta ads'},
        ],
        totalExpenses: '$2,300',
        totalIncome: '$1,300',
        startDate: '23/09/2023',
        endDate: '23/09/2023',
        quantity: 397,
        reachData: [
            {label: 'Reach', value: 300},
            {label: 'Reach', value: 30000},
            {label: 'Reach', value: 300},
        ],
    },
    {
        id: 5,
        activityName: 'Marketing Activity - 23303',
        activityDetailsLink: 'View activity details',
        activityStatus: false,
        bookName: 'Book Name',
        distribution: [{id: 1, distribution_name: 'Distribution'}, {id: 2, distribution_name: 'Branding'}, {
            id: 3,
            distribution_name: 'Marketing'
        }],
        channels: [
            {id:1,
                name: 'Amazon'},
            {id:2,name: 'google ads'},
            {id:3,name: 'meta ads'},
        ],
        totalExpenses: '$2,300',
        totalIncome: '$1,300',
        startDate: '23/09/2023',
        endDate: '23/09/2023',
        quantity: 397,
        reachData: [
            {label: 'Reach', value: 300},
            {label: 'Reach', value: 30000},
            {label: 'Reach', value: 300},
        ],
    },
];
