import React from 'react';
import {BsThreeDotsVertical} from "react-icons/bs";
import moment from "moment";
// import Restricted from "../ui/context/PermissionProvider/Restricted";

type LoadingMenuButtonProps = React.PropsWithChildren<{
    isLoading: boolean
}>;

export const LoadingMenuButton = ({isLoading}: LoadingMenuButtonProps) => {
    return (
        isLoading ? (
            <>
            loader..
            </>
            // <Spinner animation="border" role="status" size={'sm'} className='p-0 m-0'>
            //     <span className="visually-hidden">Loading...</span>
            // </Spinner>
        ) : <BsThreeDotsVertical href="#" role="button"
                                 data-bs-toggle="dropdown"
                                 className={'m-0 p-0'}
                                 aria-expanded="false"/>
    );
};

export const bytesToStandard = ((bytes: string): string => {
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];

    const parsedBytes = parseInt(bytes, 10);
    if (isNaN(parsedBytes) || parsedBytes === 0) {
        return '0 B';
    }

    const i = Math.floor(Math.log(parsedBytes) / Math.log(1024));
    const size = (parsedBytes / Math.pow(1024, i)).toFixed(2);

    return `${size} ${sizes[i]}`;
});

export  const formatNumberForAmount = (value:any) => {
    if (value >= 1000000) {
        return (value / 1000000).toFixed(2) + 'M';
    } else if (value >= 1000) {
        return (value / 1000).toFixed(2) + 'K';
    } else {
        return value.toFixed(2);
    }
};

export const NameOfMonth =(monthNumber:any)=>{
        const monthName = moment(monthNumber, 'M').format('MMMM');
    return  monthName
}
export const generateSortedMonthNames = (currentMonth: any) => {
    const monthNames = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];
    return monthNames.slice(currentMonth).concat(monthNames.slice(0, currentMonth));
};

export const modifyChannelName = (channelName:string, index:number) => {
    if (channelName === "Woo-commerce") {
        if (index === 3) {
            return "ZebraLearn(D)";
        } else if (index === 2) {
            return "ZebraLearn";
        } else {
            return "ZebraLearn";
        }
    }
    return channelName;
}

export const convertIntoRs=(value:any)=>{
    if(value){
        return value.toLocaleString("en-IN")
    }
}



