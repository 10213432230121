import React from 'react';
import GroupButton from "../../../../components/group-buttons/GroupButton";

const BrandOverview = () => {
    return (
        <>
            <div className="flex flex-col gap-4">
                <div className="relative flex justify-end">
                    <div className="absolute bottom-5">
                        <GroupButton setIsCallRange={""} isCallDateRange={""}
                                     setStartDate={""} startDate={""} endDate={""}
                                     pickerVisible={""}
                                     setEndDate={""} setPickerVisible={""}
                                     setIsDateSelect={""} isDateSelect={""}/>
                    </div>
                </div>
                <div
                    className="rounded-lg border border-stroke bg-white shadow-default dark:border-strokedark dark-bg-boxdark">
                    <div
                        className="w-full h-14 bg-[#000] rounded-tl-lg rounded-tr-lg flex flex-row justify-between items-center gap-3 text-white px-4">
                        <b className="text-2xl">Branding Overview</b>
                    </div>

                    <div className="grid grid-cols-2 p-10">
                        {/*left side amounts*/}
                        <div className=" flex flex-col gap-4">
                            <div className="grid grid-cols-3 ">
                                <div className="text-[#9F9F9F] font-Poppins text-[20px] font-medium">
                                    Total Budget
                                </div>
                                <div className="text-[22px] text-center">:</div>
                                <div className="flex gap-2 justify-start">
                                    <p className="text-[#9F9F9F] font-Poppins text-2xl font-medium leading-24">
                                        ₹
                                    </p>
                                    <p className="text-black font-Poppins text-[22px] font-medium leading-24">
                                        {Math.round(30000.333).toLocaleString('en-IN')}
                                    </p>
                                </div>
                            </div>
                            <div className="grid grid-cols-3">
                                <p className="text-[#9F9F9F] font-Poppins  text-[20px] font-medium whitespace-nowrap">
                                    Total Spent
                                </p>
                                <span className="text-[22px] text-center">:</span>
                                <div className="flex gap-2 justify-start">
                                    <p className="text-[#9F9F9F] font-Poppins text-2xl font-medium leading-24">
                                        ₹
                                    </p>
                                    <p className="text-black font-Poppins text-[22px] font-medium leading-24">
                                        {Math.round(30000.45).toLocaleString('en-IN')}
                                    </p>
                                </div>
                            </div>
                            <div className="grid grid-cols-3">
                                <p className="text-[#9F9F9F] font-Poppins  text-[20px] font-medium whitespace-nowrap">
                                    Total Reach
                                </p>
                                <span className="text-[22px] text-center">:</span>
                                <div className="flex ml-6 justify-start">
                                    <p className="text-black  font-Poppins text-[22px] font-medium leading-24">
                                        {Math.round(30000.45).toLocaleString('en-IN')}
                                    </p>
                                </div>
                            </div>
                            <div className="grid grid-cols-3">
                                <p className="text-[#9F9F9F] font-Poppins  text-[20px] font-medium whitespace-nowrap">
                                    Total Reach
                                </p>
                                <span className="text-[22px] text-center">:</span>
                                <div className="flex ml-6 justify-start">
                                    <p className="text-black font-Poppins text-[22px] font-medium leading-24">
                                        {Math.round(898.45).toLocaleString('en-IN')}
                                    </p>
                                </div>
                            </div>

                        </div>
                        {/*right side cards*/}
                        <div className="grid grid-cols-2 gap-6">
                            <div
                                className="rounded-lg border border-stroke bg-[#E3F5FF] shadow-default dark:border-strokedark dark:bg-boxdark p-10">
                                <div className="flex flex-col gap-8 justify-center items-center">
                                    <b className="text-5xl text-[#000]">897</b>
                                    <b className="text-lg text-[#9F9F9F]">Total Campaigns Created</b>
                                </div>
                            </div>
                            <div
                                className="rounded-lg border border-stroke bg-[#E3F5FF] shadow-default dark:border-strokedark dark:bg-boxdark p-10">
                                <div className="flex flex-col gap-8 justify-center items-center">
                                    <b className="text-5xl text-[#000]">234</b>
                                    <b className="text-lg text-[#9F9F9F]">Total Active Campaigns</b>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default BrandOverview;