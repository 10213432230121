import React from 'react';
import ApexCharts from 'react-apexcharts';

interface RadialBarChartProps {
    height: number;
    series: number[];
    colors: string[];
    valueColor: string;
    valueSize: string;
    showLabel: boolean;
    label: string;
    labelSize: string;
  }

function RadialBarChart({height, series, colors, valueColor, valueSize, showLabel, label}:RadialBarChartProps) {
    const options: any = {
        chart: {
            height: height || 280,
            type: 'radialBar',
            width: '200px', // Set the width here (e.g., '100%' or '400px')

        },
        series: series || [67],
        colors: colors || ['#870046'],
        plotOptions: {
            radialBar: {
                hollow: {
                    margin: 0,
                    size: '50%',
                },
                dataLabels: {
                    showOn: showLabel ? 'always' : 'never',
                    name: {
                        show: false,
                        color: '#888',
                        fontSize: '13px',
                    },
                    value: {
                        offsetY: 8,
                        color: valueColor || '#870046',
                        weight:20,
                        fontSize: valueSize || '30px',
                        show: true,
                    },
                },
            },
        },
        stroke: {
            lineCap: 'round',
        },
        labels: [label || 'Progress'],
    };

    return (
        <ApexCharts options={options} series={options.series} type="radialBar" height={options.chart.height}/>
    );
}

export default RadialBarChart;
