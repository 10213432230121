import {Config} from '../../config/network';

export const Endpoints = {
    login: () => `${Config.BASE_API_URL}/login`,
    baseProducts: `${Config.BASE_API_URL}/base-products`,
    baseProductAll: `${Config.BASE_API_URL}/base-products-all-data`,
    baseProductWithModule: `${Config.BASE_API_URL}/base-product-with-modules`,// modules like marketing and sales with date filter
    singleBaseProductPnlYearly: `${Config.BASE_API_URL}/base-single-product-pnl-yearly`,
    singleBaseProductPnlMonthly: `${Config.BASE_API_URL}/base-single-product-pnl-monthly`,
    singleBaseProductPnlWeekly: `${Config.BASE_API_URL}/base-single-product-pnl-weekly`,
    baseSingleProductWithModule: `${Config.BASE_API_URL}/base-single-product-with-modules`,
    bookRanking: `${Config.BASE_API_URL}/book-ranking`,
    productsViewData: `${Config.BASE_API_URL}/book-ranking-by-product-id`,
    productsFilterByYearly: `${Config.BASE_API_URL}/base-product-year`,
    productsFilterByWeekly: `${Config.BASE_API_URL}/base-product-weekly`,
    userProfile: `${Config.BASE_API_URL}/user`,
    fetchPNL: `${Config.BASE_API_URL}/base-products`,
    permissions: `${Config.BASE_API_URL}/abilities`,
    projectTypes: `${Config.BASE_API_URL}/projectTypes`,
    project_types: `${Config.BASE_API_URL}/project_types`,
}




