import React, {useRef} from "react";
import {SlCalender} from "react-icons/sl";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'; // Import the CSS


const GroupButton = ({
                         setIsDateSelect,
                         startDate,
                         setStartDate,
                         endDate,
                         setEndDate,
                         pickerVisible,
                         setPickerVisible,
                         isCallDateRange,
                         setIsCallRange,
                         isDateSelect
                     }:any) => {

    const buttonRef = useRef(null);

    console.log(typeof isDateSelect, "isDateSelect")
    console.log(startDate, endDate, "dateee")
    return (
        <>
            <div className="inline-flex rounded-md shadow-sm" role="group">
                <button onClick={() => {
                    isDateSelect !== 1 ? setIsDateSelect(1) : setIsDateSelect(null)
                }} type="button"
                        className={`${isDateSelect === 1 && "text-lg text-[#000] "}  px-4 py-2 text-sm font-medium text-gray-900 border border-r-0 border-gray-200 rounded-l-lg
                            hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700`}
                >
                    1m
                </button>
                <button onClick={() => {
                    isDateSelect !== 6 ?
                        setIsDateSelect(6) : setIsDateSelect(null)
                }} type="button"
                        className={`${isDateSelect === 6 && "text-lg text-[#000] "}  px-4 py-2 text-sm font-medium text-gray-900 border-t border-b border-r-0   
                            hover:bg-gray-100 hover:text-blue-700  focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700`}
                > 6m
                </button>
                <button onClick={() => {
                    isDateSelect !== 12 ?
                        setIsDateSelect(12) : setIsDateSelect(null)
                }} type="button"
                        className={`${isDateSelect === 12 && "text-lg text-[#000] "}  px-4 py-2 text-sm font-medium text-gray-900 border border-gray-200 border-l-0  border-r-0 
                            hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700`}
                > 1yr
                </button>
                <button onClick={() => {
                    isDateSelect !== 24 ?
                        setIsDateSelect(24) : setIsDateSelect(null)
                }} type="button"
                        className={`${isDateSelect === 24 && "text-lg text-[#000] "}  px-4 py-2 text-sm font-medium text-gray-900 border-t border-b border-gray-200  
                         hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700`}
                > 2yr
                </button>
                <button onClick={() => {
                    isDateSelect !== 36 ?
                        setIsDateSelect(36) : setIsDateSelect(null)
                }} type="button"
                        className={`${isDateSelect === 36 && "text-lg text-[#000]  "}  px-4 py-2 text-sm font-medium text-gray-900 border border-gray-200 
                          border-l-0 border-r-0   hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700`}
                > 3yr
                </button>

                <button ref={buttonRef}
                        type="button" onClick={() => setPickerVisible(!pickerVisible)
                }
                        className="px-4 py-2 text-sm font-medium text-gray-900 bg-black border border-gray-200 rounded-r-md hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white">
                    <SlCalender className="w-8 text-[#fff]"/>
                </button>
                <div className="relative">
                    <div
                        className={`absolute bg-white p-4 border rounded top-10 right-0  ${
                            pickerVisible ? '' : 'hidden'
                        }`}
                    >
                        <div className="flex gap-x-4">
                            <DatePicker
                                selected={startDate}
                                startDate={startDate}
                                endDate={endDate}
                                onChange={(date) => setStartDate(date)}
                                selectsStart
                                inline
                            />
                            <DatePicker
                                selected={endDate}
                                startDate={startDate}
                                endDate={endDate}
                                onChange={(date) => setEndDate(date)}
                                selectsEnd
                                inline
                            />
                        </div>
                        <div className="flex justify-end ">
                            <button onClick={() => {
                                setPickerVisible(false);
                                setIsCallRange(!isCallDateRange)
                            }} className="border w-40 h-10 bg-black text-white">Apply
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
export default GroupButton