import React from "react";
import { ProductItem} from "../../pages/book-rankings/models/SingleBookRankingResponseModel";

interface ProductsViewProps {
    productItem: ProductItem
    handleChannelData: (id: number) => void; // Accept id as a parameter
    selectedCardId:number | null
}

const ProductCards: React.FC<ProductsViewProps> = ({productItem,handleChannelData,selectedCardId}) => {

    console.log(
        "selectedCardId:", selectedCardId,
        "productItem.id:", productItem.base_product_id,
        "Are they equal?", selectedCardId === productItem.base_product_id
    );    return (
        <div onClick={() => handleChannelData(productItem.base_product_id)}
             // className="rounded-xl border border-stroke bg-white py-6 px-7.5 shadow-default dark:border-strokedark dark:bg-boxdark"
             className={`rounded-xl border border-stroke py-6 px-7.5 shadow-default dark:border-strokedark  cursor-pointer
    ${selectedCardId === productItem.base_product_id ? "bg-blue-500 "  : "bg-white"}`}
        >
                <h4 className="text-title-md font-bold text-black dark:text-white">
                    {productItem?.channel?.name}
                </h4>

            <div className="mt-4 flex items-end justify-between">
                <div>
                    <h4 className="text-sm font-bold text-black dark:text-white">
                            Total Earning
                    </h4>
                </div>

                <span className="flex items-center gap-1 text-sm font-medium text-meta-3">
         {productItem?.sales?.total_amount}
          <svg
              className="fill-meta-3"
              width="10"
              height="11"
              viewBox="0 0 10 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path
                d="M4.35716 2.47737L0.908974 5.82987L5.0443e-07 4.94612L5 0.0848689L10 4.94612L9.09103 5.82987L5.64284 2.47737L5.64284 10.0849L4.35716 10.0849L4.35716 2.47737Z"
                fill=""
            />
          </svg>
        </span>
            </div>
            <div className="mt-4 flex items-end justify-between">
                <div>
                    <h4 className="text-sm font-bold text-black dark:text-white">
                        Total Quantity
                    </h4>
                </div>

                <span className="flex items-center gap-1 text-sm font-medium text-meta-3">
                {productItem?.sales?.total_quantity}
                    <svg
                        className="fill-meta-3"
                        width="10"
                        height="11"
                        viewBox="0 0 10 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
            <path
                d="M4.35716 2.47737L0.908974 5.82987L5.0443e-07 4.94612L5 0.0848689L10 4.94612L9.09103 5.82987L5.64284 2.47737L5.64284 10.0849L4.35716 10.0849L4.35716 2.47737Z"
                fill=""
            />
          </svg>
        </span>
            </div>

        </div>
    );
};

export default ProductCards;
