import { lazy } from 'react';
import ProductsView from '../pages/book-rankings/product-view/ProductsView';
import AllBooks from '../pages/books/AllBooks';
import InventoryPages from '../pages/inventory/InventoryPages';
import DetailView from '../pages/inventory/detail-view/DetailView';
import MarketingDashboard from '../pages/marketing/MarketingDashboard';
import MarketingBrandOverview from '../pages/marketing/marketing-brand-overview/MarketingBrandOverview';
import Dashboard from '../pages/Dashboard/Dashboard';
const BookDetailsView = lazy(
  () => import('../pages/books/books-detail-view/BookDetailsView')
);
const BookRankings = lazy(() => import('../pages/book-rankings/BookRanking'));

const coreRoutes = [
  // all books pages for profit and loss
  {
    path: '/dashboard',
    title: 'Dashboard',
    component: Dashboard,
  },
  {
    path: '/all-books',
    title: 'all-books',
    component: AllBooks,
  },
  {
    path: '/all-books/detail-view/:id',
    title: 'detail-view',
    component: BookDetailsView,
  },
  // inventory pages
  {
    path: '/inventory-page',
    title: 'inventory',
    component: InventoryPages,
  },
  {
    path: '/inventory-page/detail-view/:id',
    title: 'detail-view',
    component: DetailView,
  },
  // marketing pages
  {
    path: '/marketing',
    title: 'marketing',
    component: MarketingDashboard,
  },
  {
    path: '/marketing/marketing-brand-overview',
    title: 'marketing-brand-overview',
    component: MarketingBrandOverview,
  },
  {
    path: '/rankings',
    title: 'rankings',
    component: BookRankings,
  },

  {
    path: '/rankings/product-views/:id',
    title: 'product-views',
    component: ProductsView,
  },
];

const routesPages = [...coreRoutes];
export default routesPages;
