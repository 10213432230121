import React, {useEffect} from "react";
import ChartOne from "../../components/ChartOne";
// import ChartThree from "../../components/ChartThree";
import ChartTwo from "../../components/ChartTwo";
import {useRecoilState} from "recoil";
import {profileAtom} from "../../atoms/profile";
import {LoginViewModel} from "../Authentication/SingInViewModel";
import {ProfileIterface} from "../Profile/modal/ProfileModal";
import DashboardCards from "../../components/dashboard-cards/DashboardCards";
import {useNavigate} from "react-router-dom";
import Books_icon from "../../assets/images/sidemenu icons/book.png";
import inventory_icon from "../../assets/images/sidemenu icons/inventory.png";
import marketing_icon from "../../assets/images/sidemenu icons/marketing.png";

const dashboardModuleData = [
    {
        moduleName: "All Books",
    },
    {
        moduleName: "Inventory",
    },
    {
        moduleName: "Marketing",
    },
    {
        moduleName: "Book Ranking",
    },
];
const model = LoginViewModel.instance;

// Define a type for icon mapping
type IconMap = {
    [moduleName: string]: JSX.Element;
};

const Dashboard = () => {
    const navigate = useNavigate();
    const [, setProfile] = useRecoilState<ProfileIterface>(profileAtom);

    const fetchProfile = async () => {
        try {
            const response = await model.getProfile();
            setProfile(response);
        } catch (error) {
            console.error("Login error:", error);
        }
    };

    useEffect(() => {
        fetchProfile();
    }, []);
    const imageStyles = {
        filter: "brightness(0) invert(0)", // This style inverts the colors to make the image white
    };

    const iconMap: IconMap = {
        "All Books": <img src={Books_icon} alt="" style={imageStyles}/>,
        "Inventory": <img src={inventory_icon} alt="" style={imageStyles}/>,
        "Marketing": <img src={marketing_icon} alt="" style={imageStyles}/>,
        "Book Ranking": <img src={marketing_icon} alt="" style={imageStyles}/>,
    };

    const handleModuleSelect = (moduleName: string) => {

        if (moduleName === "All Books") {
            navigate("/all-books");
        }
        if (moduleName === "Inventory") {
            navigate("/inventory-page")
        }
        if (moduleName === "Marketing") {
            navigate("/marketing");
        }
        if (moduleName === "Book Ranking") {
            navigate("/*");
        }
    };

    return (
        <>
            <div>
                <b className="text-2xl text-black ">Analytics Dashboard</b>
            </div>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 mt-10">
                {dashboardModuleData.map((module, index) => (
                    <DashboardCards
                        key={index}
                        moduleName={module.moduleName}
                        moduleIcon={iconMap[module.moduleName]}
                        handleModuleSelect={() => handleModuleSelect(module.moduleName)}
                    />
                ))}
            </div>

            <div className="mt-4 grid grid-cols-12 gap-4 md:mt-6 md:gap-6 2xl:mt-7.5 2xl:gap-7.5">
                <ChartOne/>
                <ChartTwo/>
            </div>
        </>
    );
};

export default Dashboard;
