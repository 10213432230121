import { ApexOptions } from 'apexcharts';
import React from 'react';
import ReactApexChart from 'react-apexcharts';

interface ChartOneState {
    options: ApexOptions;
    series: any;
}
    // @ts-ignore
    const LinesChart: React.FC<ChartOneState> = ({ options, series }) => {

        return (
        <div className="col-span-12 rounded-sm border border-stroke bg-white px-5 pt-7.5 pb-5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:col-span-8">
            {/*<div className="flex flex-wrap items-start justify-between gap-3 sm:flex-nowrap">*/}
            {/*    <div className="flex w-full flex-wrap gap-3 sm:gap-5">*/}
            {/*        <div className="flex min-w-47.5">*/}
            {/*<span className="mt-1 mr-2 flex h-4 w-full max-w-4 items-center justify-center rounded-full border border-primary">*/}
            {/*  <span className="block h-2.5 w-full max-w-2.5 rounded-full bg-primary"></span>*/}
            {/*</span>*/}
            {/*            <div className="w-full">*/}
            {/*                <p className="font-semibold text-primary">Total Revenue</p>*/}
            {/*                <p className="text-sm font-medium">12.04.2022 - 12.05.2022</p>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className="flex min-w-47.5">*/}
            {/*<span className="mt-1 mr-2 flex h-4 w-full max-w-4 items-center justify-center rounded-full border border-secondary">*/}
            {/*  <span className="block h-2.5 w-full max-w-2.5 rounded-full bg-secondary"></span>*/}
            {/*</span>*/}
            {/*            <div className="w-full">*/}
            {/*                <p className="font-semibold text-secondary">Total Sales</p>*/}
            {/*                <p className="text-sm font-medium">12.04.2022 - 12.05.2022</p>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}

            {/*</div>*/}

            <div>
                <div id="chartOne" className="-ml-5">
                    <ReactApexChart
                        options={options}
                        series={series}
                        type="line"
                        height={350}
                    />
                </div>
            </div>
        </div>
    );
};

export default LinesChart;
