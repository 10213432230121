import React from 'react';
import ZL_LOGO from "../../../../assets/icons/zebralogo.png";
import GroupButton from "../../../../components/group-buttons/GroupButton";
import HOME_ICON from "../../../../assets/images/house-2.png"
import INVENTORY_ICON from "../../../../assets/images/shop.png"

const InventoryOverView = () => {
    let setIsCallRange, isCallDateRange, setStartDate, startDate, endDate, pickerVisible, setEndDate, setPickerVisible,
        setIsDateSelect, isDateSelect;

    return (
        <>
            <div className="flex flex-col gap-4">
                <div className="flex justify-between">
                    <div className="flex flex-row items-center gap-3">
                        <b className="text-black font-Poppins text-lg  whitespace-nowrap">
                            name of book
                        </b>
                        <p className="flex items-center justify-center">
                            by
                        </p>
                        <div className="flex gap-2 items-center">
                            <div className="w-5 h-5">
                                <img src={ZL_LOGO} alt=""/>
                            </div>
                            <b className="text-black font-Poppins text-sm  leading-24">
                                Zebra Learn
                            </b>
                        </div>
                    </div>
                    <GroupButton setIsCallRange={setIsCallRange} isCallDateRange={isCallDateRange}
                                 setStartDate={setStartDate} startDate={startDate} endDate={endDate}
                                 pickerVisible={pickerVisible}
                                 setEndDate={setEndDate} setPickerVisible={setPickerVisible}
                                 setIsDateSelect={setIsDateSelect} isDateSelect={isDateSelect}/>
                </div>
                <div
                    className="rounded-lg border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark flex flex-col p-4">
                    <div className="flex justify-start mb-6">
                        <b className="text-black text-2xl">Inventory Overview</b>
                    </div>
                    <div className="grid grid-cols-2">
                        {/*left side amounts*/}
                        <div className=" flex flex-col gap-2">
                            <div className="grid grid-cols-3 ">
                                <div className="text-[#9F9F9F] font-Poppins text-[20px] font-medium">
                                    Current stock
                                </div>
                                <div className="text-[22px] text-center">:</div>
                                <div className="flex gap-2">
                                    <p className="text-[#9F9F9F] font-Poppins text-2xl font-medium leading-24">
                                        ₹
                                    </p>
                                    <p className="text-black font-Poppins text-[22px] font-medium leading-24">
                                        {435.45.toLocaleString('en-IN')}
                                    </p>
                                </div>
                            </div>
                            <div className="grid grid-cols-3">
                                <p className="text-[#9F9F9F] font-Poppins  text-[20px] font-medium whitespace-nowrap">
                                    Average Purchased Cost
                                </p>
                                <span className="text-[22px] text-center">:</span>
                                <div className="flex gap-2">
                                    <p className="text-[#9F9F9F] font-Poppins text-2xl font-medium leading-24">
                                        ₹
                                    </p>
                                    <p className="text-black font-Poppins text-[22px] font-medium leading-24">
                                        {435435.45.toLocaleString('en-IN')}
                                    </p>
                                </div>
                            </div>
                            <div className="grid grid-cols-3">
                                <p className="text-[#9F9F9F] font-Poppins  text-[20px] font-medium whitespace-nowrap">
                                    Average Purchased Cost
                                </p>
                                <span className="text-[22px] text-center">:</span>
                                <div className="flex gap-2">
                                    <p className="text-[#9F9F9F] font-Poppins text-2xl font-medium leading-24">
                                        ₹
                                    </p>
                                    <p className="text-black font-Poppins text-[22px] font-medium leading-24">
                                        {4355.45.toLocaleString('en-IN')}
                                    </p>
                                </div>
                            </div>
                            <div className="grid grid-cols-3">
                                <p className="text-[#9F9F9F] font-Poppins  text-[20px] font-medium whitespace-nowrap">
                                    Average Purchased Cost
                                </p>
                                <span className="text-[22px] text-center">:</span>
                                <div className="flex gap-2">
                                    <p className="text-[#9F9F9F] font-Poppins text-2xl font-medium leading-24">
                                        ₹
                                    </p>
                                    <p className="text-black font-Poppins text-[22px] font-medium leading-24">
                                        {435435.45.toLocaleString('en-IN')}
                                    </p>
                                </div>
                            </div>
                            <div className="grid grid-cols-3">
                                <p className="text-[#9F9F9F] font-Poppins  text-[20px] font-medium whitespace-nowrap">
                                    Average Purchased Cost
                                </p>
                                <span className="text-[22px] text-center">:</span>
                                <div className="flex gap-2">
                                    <p className="text-[#9F9F9F] font-Poppins text-2xl font-medium leading-24">
                                        ₹
                                    </p>
                                    <p className="text-black font-Poppins text-[22px] font-medium leading-24">
                                        {435435.45.toLocaleString('en-IN')}
                                    </p>
                                </div>
                            </div>

                        </div>
                        {/*right side cards*/}
                        <div className="grid grid-cols-2 gap-6">
                            <div className="col-span-1">
                                <div
                                    className="rounded-lg border border-stroke bg-[#E3F5FF] shadow-default dark:border-strokedark dark:bg-boxdark py-6">
                                    <div className="flex flex-col gap-3">
                                        <div className="flex justify-center">
                                            <div className="flex flex-col gap-2 justify-center items-center">
                                                <div
                                                    className="h-10 w-10 rounded-full bg-[#D8E9F2] flex items-center justify-center">
                                                    <img src={HOME_ICON} alt=""/>
                                                </div>
                                                <b className="text-xl text-[#000]">Main Office</b>
                                            </div>
                                        </div>
                                        <div className="flex flex-row justify-evenly">
                                            <div className="flex flex-col gap-2 items-center">
                                                <p className="text-sm">Quantity</p>
                                                <b className="text-2xl text-[#000]">397</b>
                                            </div>
                                            <div className="flex flex-col gap-2 items-center">
                                                <p className="text-sm">Stock Amount</p>
                                                <b className="text-2xl text-[#000]">₹8989</b>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-1">
                                <div className="flex flex-col gap-6">
                                    <div className="flex flex-col">
                                        <div
                                            className="rounded-lg border border-stroke bg-[#E5ECF5] shadow-default dark:border-strokedark dark:bg-boxdark py-3">
                                            <div className="flex flex-row justify-evenly">
                                                <div className="flex flex-row gap-4">
                                                    <div
                                                        className="h-10 w-10 rounded-full bg-[#D8E9F2] flex items-center justify-center">
                                                        <img src={INVENTORY_ICON} alt=""/>
                                                    </div>
                                                    <b className="text-lg text-[#000]">
                                                        Bheston
                                                    </b>
                                                </div>
                                                <div className="flex gap-6">
                                                    <div className="flex flex-col gap-2 items-center">
                                                        <p className="text-sm">Quantity</p>
                                                        <b className="text-2xl text-[#000]">397</b>
                                                    </div>
                                                    <div className="flex flex-col gap-2 items-center">
                                                        <p className="text-sm">Stock Amount</p>
                                                        <b className="text-2xl text-[#000]">₹8989</b>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-col ">
                                        <div
                                            className="rounded-lg border border-stroke bg-[#E3F5FF] shadow-default dark:border-strokedark dark:bg-boxdark py-3">
                                            <div className="flex flex-row justify-evenly">
                                                <div className="flex flex-row gap-4">
                                                    <div
                                                        className="h-10 w-10 rounded-full bg-[#D8E9F2] flex items-center justify-center">
                                                        <img src={INVENTORY_ICON} alt=""/>
                                                    </div>
                                                    <b className="text-lg text-[#000]">
                                                        Bheston
                                                    </b>
                                                </div>
                                                <div className="flex gap-6">
                                                    <div className="flex flex-col gap-2 items-center">
                                                        <p className="text-sm">Quantity</p>
                                                        <b className="text-2xl text-[#000]">397</b>
                                                    </div>
                                                    <div className="flex flex-col gap-2 items-center">
                                                        <p className="text-sm">Stock Amount</p>
                                                        <b className="text-2xl text-[#000]">₹8989</b>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </>
    )
}
export default InventoryOverView