import React from 'react';

interface DashboardCardsProps {
  moduleIcon: any;
  moduleName: any;
  handleModuleSelect: any;
}

const DashboardCards: React.FC<DashboardCardsProps> = ({
  moduleIcon,
  moduleName,
  handleModuleSelect,
}) => {
  return (
    <>
      <div
        onClick={handleModuleSelect}
        className="rounded-xl border border-stroke bg-white py-5 shadow-default dark:border-strokedark dark:bg-boxdark cursor-pointer"
      >
        <div className="mt-4 px-4 flex items-center justify-between">
          <b className="flex items-center justify-center text-[#000]">
            {moduleName}
          </b>
          <div className="flex items-center justify-center rounded-full bg-meta-2 dark:bg-meta-4 h-11.5 w-11.5">
            {moduleIcon}
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardCards;
