import React, {useState} from "react";
import {dummyData} from "./data";
import TablePagination from "../../../components/pagination/TablePagination";
import {useNavigate} from "react-router-dom";

const InventoryTableDetails = () => {
    const navigate = useNavigate()
    const getColorAndLevel = (value:any) => {
        if (value < 900) {
            return {color: "#FE5512", level: "Min "};
        } else if (value >= 900 && value < 1200) {
            return {color: "#25D228", level: "Max "};
        } else {
            return {color: "#DB1B1B", level: "High "};
        }
    };
    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(dummyData.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = currentPage * itemsPerPage;
    const currentPageUpdatedData = dummyData.slice(startIndex, endIndex);

    const handlePageChange = (newPage:any) => {
        setCurrentPage(newPage);
    };

    const tableHeader = [
        {
            id: 1,
            header_name: "Asset Image"
        },
        {
            id: 2,
            header_name: "Asset Name"
        },
        {
            id: 3,
            header_name: "Warehouse Stocks"
        },
        {
            id: 4,
            header_name: "Avg Cost"
        },
        {
            id: 5,
            header_name: "Current Stocks"
        },
        {
            id: 6,
            header_name: "Danger Level"
        }
    ]
    return (
        <div
            className="rounded-xl border border-stroke bg-white shadow-default dark:border-strokedark dark-bg-boxdark"
        >
            <div className="max-w-full overflow-x-auto">
                <table className="w-full table-auto">
                    <thead>
                    <tr className="border-b-2 border-[#DEDFE3]  text-left dark:bg-meta-4">
                        {
                            tableHeader.map((items,index)=>(
                                <th key={index} className="min-w-[220px] py-4 px-4 border-r border-[#DEDFE3] text-center text-black dark-text-white xl:pl-11 font-semibold">
                                    {items?.header_name}
                                </th>
                            ))
                        }
                    </tr>
                    </thead>
                    <tbody>
                    <>
                        {currentPageUpdatedData.map((item, index) => {
                            const {color, level} = getColorAndLevel(item?.fifthCol);

                            return (
                                <tr key={index}>
                                    <td onClick={() => navigate(`/inventory-page/detail-view/${item?.id}`)}
                                        className="border border-[#eee] py-3 px-3 dark-border-strokedark">
                                        <div className="flex items-center justify-center cursor-pointer">
                                            <div className="w-36">
                                                <img src={`images/books/${item?.id}.png`} alt=""/>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="border border-[#eee] py-3 px-3 dark-border-strokedark">
                                        <div className="text-center text-black font-semibold">{item?.thirdCol}</div>
                                    </td>
                                    <td className="border border-[#eee] p-6 dark-border-strokedark">
                                        <div
                                            className="rounded-lg border border-stroke bg-white shadow-default dark:border-strokedark dark-bg-boxdark">
                                            <div
                                                className="w-full h-11 bg-[#000] rounded-tl-lg rounded-tr-lg flex flex-row justify-between items-center gap-3 text-white px-4">
                                                <b>{item?.warehouseData?.warehouseName}</b>
                                                <b>{item?.warehouseData?.currentStock}</b>
                                            </div>
                                            <div className="flex flex-col gap-3 mt-2">
                                                {item?.warehouseData?.items.map((warehouseItem, wIndex) => (
                                                    <div key={wIndex}
                                                         className="flex flex-row justify-between items-center px-6">
                                                        <span className="text-[#9F9F9F]">{warehouseItem?.itemName}</span>
                                                        <span
                                                            className="text-black font-semibold">{warehouseItem?.itemStock}</span>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </td>
                                    <td className="border border-[#eee] py-4 px-4 dark-border-strokedark">
                                        <div className="text-center text-black font-semibold">{item?.fourthCol}</div>
                                    </td>
                                    <td className="border border-[#eee] py-4 px-4 dark-border-strokedark">
                                        <div className="text-center text-black font-semibold">
                                            {item?.fifthCol}
                                        </div>
                                    </td>
                                    <td className="border border-[#eee]  px-8 dark-border-strokedark">
                                        <div className="text-center">
                                            <div
                                                style={{
                                                    color,
                                                    fontWeight: "bold",
                                                    fontSize: "16px",
                                                    textAlign: "center",
                                                    backgroundColor: color === "#FE5512" ? "#FFE4E1" : color === "#25D228" ? "#E0FFE0" : "#FFB6C1",
                                                }}
                                                className={`flex-shrink-0 rounded-full bg-${color} bg-opacity-6  py-2  font-medium text-${color}`}
                                            >
                                                {level}
                                            </div>
                                        </div>
                                    </td>

                                </tr>
                            );
                        })}
                    </>
                    </tbody>
                </table>
            </div>
            {/*pagination of table*/}
            <TablePagination
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                totalPages={totalPages}
                handlePageChange={handlePageChange}
                startIndex={startIndex}
                endIndex={endIndex}
                dummyData={dummyData}
            />
        </div>
    );
};

export default InventoryTableDetails;
