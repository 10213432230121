import React from "react";

interface DatePickerRangeProps {
    from: string;
    to: string;
    handleFromDateChange: any;
    handleToDateChange: any;
    clearFilter: any
    fromDate: string
    forBooks?: true
}

const FiltersComponent: React.FC<DatePickerRangeProps> = ({
                                                              from,
                                                              to,
                                                              handleFromDateChange,
                                                              handleToDateChange,
                                                              clearFilter,
                                                              fromDate,
                                                              forBooks
                                                          }) => {


    return (
        <>
            <div className="flex gap-x-4">
                <div className=" flex whitespace-nowrap items-center gap-x-2">
                    <p className='text-["20px"] font-semibold  break-words text-black'>
                        Date From</p>
                    <input
                        type="date"
                        className="custom-input-date text-[#9F9F9F] custom-input-date-1 border-[#9F9F9F] w-70 rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                        id="fromDate"
                        name="from"
                        placeholder="from"
                        value={from}
                        onChange={handleFromDateChange}
                    />
                </div>
                <div>
                    <div className="flex whitespace-nowrap items-center gap-x-2">
                        <p className='text-["20px"] font-semibold  break-words text-black'>Date To</p>
                        <input
                            type="date"
                            className="custom-input-date text-[#9F9F9F] custom-input-date-1 border-[#9F9F9F] w-70 rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                            min={from}
                            id="toDate"
                            name="to"
                            value={to}
                            onChange={handleToDateChange}
                        />
                    </div>
                </div>

                <div className="flex justify-between w-full items-center">
                    <div>
                        <button onClick={clearFilter}
                                className={`${fromDate !== "" ? "bg-black text-[#fff]" : ""} flex  rounded-md  py-3 px-8 font-medium text-white hover:bg-opacity-90 bg-[#9F9F9F] text-lg font-semibold  break-words `}
                        >
                            Clear filter
                        </button>
                    </div>
                    {!forBooks &&
                        <>
                            <select
                                className="flex rounded-md  border float-left py-2  px-2 font-medium text-black text-lg font-semibold  break-words text-black  w-35">
                                <option value="1" className="h-10">All Years</option>
                                <option value="2">last 2 years</option>
                                <option value="3">last 3 years</option>
                                <option value="4">last 4 years</option>
                                <option value="5">last 5 years</option>
                            </select>
                        </>
                    }
                </div>
            </div>
        </>
    );
};

export default FiltersComponent;
