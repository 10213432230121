import React from 'react';

interface PaginationProps {
    itemsPerPage: number;
    currentPage: number;
    totalPages: number;
    handlePageChange: (newPage: number) => void;
    startIndex: number;
    endIndex: number;
    dummyData: any[]; // Change the type to match your data
}

function TablePagination(props: PaginationProps) {
    const {
        // itemsPerPage,
        currentPage,
        totalPages,
        handlePageChange,
        startIndex,
        endIndex,
        dummyData,
    } = props;

    return (
        <div className="flex justify-between p-4">
            <div>
                Showing {startIndex + 1} to {Math.min(endIndex, dummyData.length)} of {dummyData.length} entries
            </div>
            <div className="inline-flex rounded-md shadow-sm" role="group">
                <button
                    disabled={currentPage === 1}
                    onClick={() => handlePageChange(currentPage - 1)}
                    type="button"
                    className="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-l-lg hover:bg-gray-100 hover-text-blue-700 focus:z-10 focus-ring-2 focus-ring-blue-700 focus-text-blue-700 dark-bg-gray-700 dark-border-gray-600 dark-text-white dark-hover-text-white dark-hover-bg-gray-600 dark-focus-ring-blue-500 dark-focus-text-white"
                >
                    Prev
                </button>
                <button
                    type="button"
                    className="px-4 py-2 text-sm text-white font-medium bg-black border-gray-200 focus-z-10 focus-ring-2 focus-ring-blue-700"
                >
                    {currentPage}
                </button>
                <button
                    disabled={currentPage === totalPages}
                    onClick={() => handlePageChange(currentPage + 1)}
                    type="button"
                    className="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-r-md hover-bg-gray-100 hover-text-blue-700 focus-z-10 focus-ring-2 focus-ring-blue-700 focus-text-blue-700 dark-bg-gray-700 dark-border-gray-600 dark-text-white dark-hover-text-white dark-hover-bg-gray-600 dark-focus-ring-blue-500 dark-focus-text-white"
                >
                    Next
                </button>
            </div>
        </div>
    );
}

export default TablePagination;
