import axios, { AxiosRequestConfig, Method } from "axios";
import Cookies from "js-cookie";

export class ApiClient {
    // region MakeAPICall
    async makeApiCall(method: Method, url: string, handleError: Function, params?: object): Promise<any> {
        try {
            const token = Cookies.get('token')

            const config: AxiosRequestConfig = {
                method: method,
                url: url,
                data: method !== 'GET' ? params : undefined,
                params: method === 'GET' ? params : undefined,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': token ? `Bearer ${token}` : '',
                },
            };

            const response = await axios(config);

            return response.data;
        } catch (err) {
            handleError(err);
        }
    }
}
