import {BaseViewModel} from "../../data/base/BaseViewModel";
import {BookRankingResponseModel} from "./models/BookRankingResponseModel";
import {PaginationParams} from "../../data/network/AppApiService";
import {SingleBookRankingResponseModel} from "./models/SingleBookRankingResponseModel";
import {YearlyProductResponseModel} from "./models/YearlyProductResponseModel";

export class BookRankingViewModel extends BaseViewModel {
    static _instance: BookRankingViewModel = new BookRankingViewModel();

    public static get instance() {
        if (BookRankingViewModel._instance === undefined) {
            BookRankingViewModel._instance = new BookRankingViewModel();
        }

        return BookRankingViewModel._instance;
    }

    async getBookRanking(params: PaginationParams): Promise<BookRankingResponseModel> {
        return await this.getApiService().getBookRankingApiCall(this.handleError, params);
    }
    async getProductViewData(params: any): Promise<SingleBookRankingResponseModel> {
        return await this.getApiService().getProductViewData(this.handleError, params);
    }
    async getProductsDetailApiCallFilterByYearly(params: any): Promise<YearlyProductResponseModel> {
        return await this.getApiService().getProductsDetailApiCallFilterByYearly(this.handleError, params);
    }
    async getProductsDetailApiCallFilterByWeekly(params: any): Promise<any> {
        return await this.getApiService().getProductsDetailApiCallFilterByWeekly(this.handleError, params);
    }
}
