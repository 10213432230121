import {BaseViewModel} from "../../data/base/BaseViewModel";
import {BooksResponseModel} from "./models/BookResponseModel";
import {PaginationParams} from "../../data/network/AppApiService";
// import {BaseProductPnlModulesResponseModel} from "./models/BaseProductPnlModulesResponseModel";
import {SingleBaseProductPnlModel} from "./models/SingleBaseProductPnlModel";

export class BooksViewModel extends BaseViewModel {
    static _instance: BooksViewModel = new BooksViewModel();

    public static get instance() {
        if (BooksViewModel._instance === undefined) {
            BooksViewModel._instance = new BooksViewModel();
        }
        return BooksViewModel._instance;
    }
    async getBooksData(params: PaginationParams): Promise<BooksResponseModel> {
        return await this.getApiService().getBaseProductForBooks(this.handleError, params);
    }
    async getBaseSingleProductWithModule(params:  any ): Promise<any> {
        return await this.getApiService().getBaseSingleProductWithModule(this.handleError, params);
    }

    async getSingleBaseProductPnlYearly(params:  any ): Promise<SingleBaseProductPnlModel> {
        return await this.getApiService().getSingleBaseProductPnlYearly(this.handleError, params);
    }

}
