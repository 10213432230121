import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import {campaignTableData} from "../data";
import TablePagination from "../../../components/pagination/TablePagination";


//status active or inactive
const StatusButton = ({isActive}:any) => {
    return (
        <>
            <div
                className={`rounded-full ${isActive ? "bg-[#D3F9D8] text-[#25D228] " : "bg-[#D9D9D9] text-[#878787] "} w-1/2 text-center py-2`}>
                {isActive ? "Active" : "Inactive"}
            </div>
        </>
    )
}

const MarketingCampaignTable = () => {
    const navigate = useNavigate()
    const itemsPerPage = 4;
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(campaignTableData.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = currentPage * itemsPerPage;
    const currentPageUpdatedData = campaignTableData.slice(startIndex, endIndex);
    const handlePageChange = (newPage:number) => {
        setCurrentPage(newPage);
    };

    const campaignTableHeader = [
        {
            id: 1,
            header_name: "New Activity"
        },
        {
            id: 2,
            header_name: "Products"
        },
        {
            id: 3,
            header_name: "Categories"
        },
        {
            id: 4,
            header_name: "Channel"
        },
        {
            id: 5,
            header_name: "Budget"
        },
        {
            id: 6,
            header_name: "Amount Spent"
        }, {
            id: 7,
            header_name: " Start Date"
        }, {
            id: 8,
            header_name: "End Date"
        }, {
            id: 9,
            header_name: "Sale"
        }, {
            id: 10,
            header_name: "View"
        }
    ]

    return (
        <>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-base text-white  bg-black dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        {campaignTableHeader.map((items, index) => (
                            <th key={index} scope="col" className="px-6 py-3">
                                {items.header_name}
                            </th>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    {currentPageUpdatedData.map((rowData, index) => (
                        <tr key={index} className="bg-white border-b-6 border-[#F3F3F3] dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                            <td
                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                <div className="flex flex-col gap-2">
                                    <b className="text-base text-black">{rowData.activityName}</b>
                                    <a href='*' className="text-[#0071D8] text-sm underline cursor-pointer"
                                       onClick={() => navigate('*')}>{rowData.activityDetailsLink}</a>
                                    <StatusButton isActive={rowData.activityStatus}/>
                                </div>
                            </td>
                            <td className="py-4">
                                <div className="flex flex-col items-center gap-2">
                                    <div className="w-18 h-18">
                                        <img src={`images/books/1.png`} alt={""}/>
                                    </div>
                                    <b className="text-sm text-black">{rowData.bookName}</b>
                                </div>
                            </td>
                            <td className="px-4 py-4">
                                <div className="flex flex-col gap-2">
                                    {rowData.distribution.map((items, index) => (
                                        <div
                                            className={`flex flex-row gap-3 justify-between border rounded px-1 py-1 ${items.distribution_name === "Distribution" ? "border-[#25D228]" : (items.distribution_name === "Branding" ? "border-[#1DAAEB]" : (items.distribution_name === "Marketing" ? "border-[#9C48DE]" : ""))}  border-2 `}>
                                            <img
                                                src={`images/marketing-icons/table-icons/${items.distribution_name}.png`}
                                                alt=""/>
                                            <b className={`${items.distribution_name === "Distribution" ? "text-[#25D228]" : (items.distribution_name === "Branding" ? "text-[#1DAAEB]" : (items.distribution_name === "Marketing" ? "text-[#9C48DE]" : ""))} text-start w-full`}>{items.distribution_name}</b>
                                        </div>
                                    ))}
                                </div>
                            </td>
                            <td className="py-6 px-6 text-center">
                                {rowData.channels.map((items, index) => (
                                    <div key={index} className="flex flex-row justify-between ">
                                        <div className="h-8 w-8">
                                            <img src={`images/marketing-icons/table-icons/${items.id}.png`} alt=""/>
                                        </div>
                                        <b className="text-[#000] text-base whitespace-nowrap w-full text-start">{items.name}</b>
                                    </div>
                                ))}
                                <div>
                                    <b className=" text-sm cursor-pointer">& more Channels</b>
                                </div>

                            </td>
                            <td className="px-4 py-6">
                                <b className="text-2xl text-black">{rowData.totalExpenses}</b>
                            </td>
                            <td className="px-4 py-6">
                              <b className="text-2xl text-[#FF4545]">${rowData.totalIncome}</b>
                            </td>
                            <td className="px-4 py-6">
                                <b className="text-base text-black">{rowData.startDate}</b>
                            </td>
                            <td className="px-4 py-6">
                                <b className="text-base text-black">{rowData.endDate}</b>
                            </td>
                            <td className="px-4 py-6">
                                <div className="flex flex-col gap-2">
                                    <b className="text-sm text-[#9F9F9F]">Quantity</b>
                                    <b className="text-black text-lg">{rowData.quantity}</b>
                                </div>
                            </td>
                            <td className="px-6 py-6">
                                {rowData.reachData.map((items, index) => (
                                    <div key={index} className="flex flex-row justify-between gap-8">
                                        <b className="text-sm text-[#878787]">{items.label}</b>
                                        <b className="text-sm text-[#000]  w-full text-start">{items.value}</b>
                                    </div>
                                ))}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                <TablePagination
                    itemsPerPage={itemsPerPage}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    handlePageChange={handlePageChange}
                    startIndex={startIndex}
                    endIndex={endIndex}
                    dummyData={campaignTableData}
                />
            </div>

        </>
    )
}

export default MarketingCampaignTable;