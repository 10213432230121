import React from 'react';
import {MdOutlineDateRange} from "react-icons/md";
import RadialBarChart from "../../components/all-types-chart/radial-bar-chart/RadialBarChart";
import {cardDataForRadialChart, brandCardData} from "./data";
import MarketingCampaignTable from "./campaigns-table/CampaignTable";
import {useNavigate} from "react-router-dom";

const MarketingDashboard = () => {
    const navigate = useNavigate();
    type BrandId = '1' | '2' | '3';

    const colorMap: Record<BrandId, string> = {
      '1': '#1DAAEB', // Brand 1
      '2': '#9C48DE', // Brand 2
      '3': '#48D06C', // Brand 3
    };
    
    const getBackgroundColor = (id: BrandId) => {
      return colorMap[id] || '#fff'; // Default color if id not found
    };
    
    const handleCardOverview = (id:any) => {
        console.log(id)
        if (id === 1) {
            navigate('marketing-brand-overview')
        }
        if (id === 2) {
            navigate("marketing-overview")
        }
        if (id === 3) {
            navigate("distribution-overview")
        }
    }

    return (
        <>
            <div className="card-header border-0">
                <div className="card-tools flex flex-col gap-7">
                    {/*button section*/}
                    <div className="flex flex-row justify-between items-center">
                        <div className="bg-black h-10 w-80 flex items-center justify-center text-white text-xl rounded">
                            Marketing Overview
                        </div>
                        <div className="flex justify-end gap-4">
                            <div className="inline-flex rounded-md shadow-sm" role="group">
                                <button type="button"
                                        className="px-4 py-2 text-base font-medium text-gray-900 bg-white border-y border-l border-gray-200 rounded-l-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white">
                                    All Time
                                </button>
                                <button type="button"
                                        className="px-4 py-2 text-base font-medium text-gray-900 bg-white border-y   border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white">
                                    12 Months
                                </button>
                                <button type="button"
                                        className="px-4 py-2 text-base font-medium text-gray-900 bg-white border-y border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white">
                                    30 Days
                                </button>
                                <button type="button"
                                        className="px-4 py-2 text-base font-medium text-gray-900 bg-white border-y  border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700  dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white">
                                    07 Days
                                </button>
                                <button type="button"
                                        className="px-4 py-2 text-base font-medium text-gray-900 bg-white border-y border-r border-gray-200 rounded-r-md hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white">
                                    24 Hours
                                </button>
                            </div>
                            <button type="button"
                                    className="px-4 py-2  font-medium text-gray-900 bg-white border-y border border-gray-200 rounded-md hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white">
                                <div className="flex gap-4 items-center">
                                    <MdOutlineDateRange/>
                                    <p className="text-base	 font-medium text-center text-gray-900">Select Date</p>
                                </div>
                            </button>
                        </div>
                    </div>
                    {/*card section*/}
                    <div className="flex space-x-4">
                        {cardDataForRadialChart.map((items, index) => (
                            <div key={index}
                                 className="w-1/3 bg-gray-200 p-4 rounded-2xl border border-stroke bg-[#fff] shadow-default dark:border-strokedark dark:bg-boxdark">
                                <div className="flex flex-row  items-center justify-start ">
                                    <div className=' w-40'>
                                        <RadialBarChart
                                            height={140}
                                            series={items.series}
                                            colors={items.colors}
                                            valueColor={items.valueColor}
                                            valueSize="16px"
                                            showLabel={true}
                                            label="" labelSize={''}                                        />
                                    </div>
                                    <div className="flex flex-col gap-4">
                                        <p className="whitespace-nowrap text-[#A0AEC0] text-lg">{items.label}</p>
                                        <b className="text-black text-2xl">{items.totalBudget}</b>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    {/* bigger card section*/}
                    <div className="flex space-x-4">
                        {brandCardData.map((data:any, index) => (
                            <div key={index} style={{backgroundColor: getBackgroundColor(data?.id)}}
                                 onClick={() => handleCardOverview(data.id)}
                                 className="w-1/3 bg-gray-200 p-6 rounded-2xl border border-stroke bg-[#0BD1F4] text-white shadow-default dark:border-strokedark dark-bg-boxdark cursor-pointer">
                                <div className="flex flex-row justify-between">
                                    <div className="flex flex-col justify-between gap-6">
                                        <div>
                                            <b className="text-lg">{data.title}</b>
                                        </div>
                                        <div className="flex flex-col">
                                            <b className="text-3xl">{data.campaignsCreated}</b>
                                            <b className="text-lg">total campaign created</b>
                                        </div>
                                        <div className="flex flex-col">
                                            <b className="text-3xl">{data.liveCampaigns}</b>
                                            <b className="text-lg">current live campaign</b>
                                        </div>
                                    </div>
                                    <div className="flex flex-col justify-between">
                                        <div className="flex justify-end">
                                            <div className="w-10 h-10">
                                                <img src={`images/marketing-icons/${data.id}.png`} alt=""/>
                                            </div>
                                        </div>
                                        <div className="flex flex-col justify-end items-center">
                                            <b className="text-3xl">{data.totalExpenses}</b>
                                            <b className="text-lg">total expenses</b>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    {/*marketing campaign table*/}
                    <div><b className="text-2xl text-black">Miscellaneous Campaigns</b> <b
                        className="text-2xl text-[#9F9F9F]">(556 Campaign)</b></div>
                    <MarketingCampaignTable/>

                </div>
            </div>
        </>
    )
}

export default MarketingDashboard